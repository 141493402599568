import { version } from '../version';
import { config, storageKeys } from './config';
import { routes } from './routes';

export const environment = {
  production: false,
  staging: false,
  test: true,
  version,
  config,
  storageKeys,
  routes,
  apiBaseUrl: 'https://api-test.conecta-facens.ligafacens.com.br',
  sentry: {
    environment: 'test',
    dsn: 'https://ed3b928ebdd049a3ab976805eed01f79@sentry.ligafacens.com/107',
    release: version,
  },
  wordpress: {
    base: 'https://conecta.facens.br',
    showcase: '/vitrine',
    categories: '/conexoes',
  },
  ipfacens: {
    base: 'https://ipfacens.com.br/',
    contact: '/contato',
  },
  firebase: {
    apiKey: 'AIzaSyAFNJXB796YXk9O3p8WtFtsbws4fUAa3WY',
    authDomain: 'test-conectafacens.firebaseapp.com',
    projectId: 'test-conectafacens',
    storageBucket: 'test-conectafacens.appspot.com',
    messagingSenderId: '1021372213363',
    appId: '1:1021372213363:web:0f004b10f70a4dd71ba206',
    measurementId: 'G-XW4CH3Y8CS',
  },
};
